define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/cb98eaf1228595fd9717907c5e4ce59c11c43658/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./regexp"], function (dojo, regexp) {
  // module:
  //		dojo/cookie

  /*=====
  var __cookieProps = {
  	// expires: Date|String|Number?
  	//		If a number, the number of days from today at which the cookie
  	//		will expire. If a date, the date past which the cookie will expire.
  	//		If expires is in the past, the cookie will be deleted.
  	//		If expires is omitted or is 0, the cookie will expire when the browser closes.
  	// path: String?
  	//		The path to use for the cookie.
  	// domain: String?
  	//		The domain to use for the cookie.
  	// secure: Boolean?
  	//		Whether to only send the cookie on secure connections
  };
  =====*/

  dojo.cookie = function ( /*String*/name, /*String?*/value, /*__cookieProps?*/props) {
    // summary:
    //		Get or set a cookie.
    // description:
    //		If one argument is passed, returns the value of the cookie
    //		For two or more arguments, acts as a setter.
    // name:
    //		Name of the cookie
    // value:
    //		Value for the cookie
    // props:
    //		Properties for the cookie
    // example:
    //		set a cookie with the JSON-serialized contents of an object which
    //		will expire 5 days from now:
    //	|	require(["dojo/cookie", "dojo/json"], function(cookie, json){
    //	|		cookie("configObj", json.stringify(config, {expires: 5 }));
    //	|	});
    //
    // example:
    //		de-serialize a cookie back into a JavaScript object:
    //	|	require(["dojo/cookie", "dojo/json"], function(cookie, json){
    //	|		config = json.parse(cookie("configObj"));
    //	|	});
    //
    // example:
    //		delete a cookie:
    //	|	require(["dojo/cookie"], function(cookie){
    //	|		cookie("configObj", null, {expires: -1});
    //	|	});
    var c = document.cookie,
      ret;
    if (arguments.length == 1) {
      var matches = c.match(new RegExp("(?:^|; )" + regexp.escapeString(name) + "=([^;]*)"));
      ret = matches ? decodeURIComponent(matches[1]) : undefined;
    } else {
      props = props || {};
      // FIXME: expires=0 seems to disappear right away, not on close? (FF3)  Change docs?
      var exp = props.expires;
      if (typeof exp == "number") {
        var d = new Date();
        d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
        exp = props.expires = d;
      }
      if (exp && exp.toUTCString) {
        props.expires = exp.toUTCString();
      }
      value = encodeURIComponent(value);
      var updatedCookie = name + "=" + value,
        propName;
      for (propName in props) {
        updatedCookie += "; " + propName;
        var propValue = props[propName];
        if (propValue !== true) {
          updatedCookie += "=" + propValue;
        }
      }
      document.cookie = updatedCookie;
    }
    return ret; // String|undefined
  };
  dojo.cookie.isSupported = function () {
    // summary:
    //		Use to determine if the current browser supports cookies or not.
    //
    //		Returns true if user allows cookies.
    //		Returns false if user doesn't allow cookies.

    if (!("cookieEnabled" in navigator)) {
      this("__djCookieTest__", "CookiesAllowed");
      navigator.cookieEnabled = this("__djCookieTest__") == "CookiesAllowed";
      if (navigator.cookieEnabled) {
        this("__djCookieTest__", "", {
          expires: -1
        });
      }
    }
    return navigator.cookieEnabled;
  };
  return dojo.cookie;
});